module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Overworld","short_name":"starter","start_url":"/","background_color":"#201728","theme_color":"#201728","display":"minimal-ui","icon":"/workspace/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c1929164772a209e924a88cdadce4861"},
    },{
      plugin: require('../node_modules/@superrb/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"cookieName":"gatsby-gdpr-linked-in"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
